/*explain：;@author: cy; @version: 1.0; date: 2022/4/12;*/
import http from './http';
const path = '/fds/file/upload/';
const paths = '/fds/api/data/upgrade';
const apis = '/fds/api/license';
const pathAccount = '/upms/file';

function uploadImg(data, urlParams) {
  return http.post(
    path + 'image' + urlParams,
    data,
    {
      'Content-Type': 'multipart/form-data',
    },
    {},
    {},
    60000
  );
}
function uploadAvatar(data, urlParams) {
  return http.post(
    pathAccount + urlParams,
    data,
    {
      'Content-Type': 'multipart/form-data',
    },
    {},
    {},
    60000
  );
}
function uploadFile(data, urlParams) {
  return http.post(
    paths + '/version' + urlParams,
    data,
    {
      'Content-Type': 'multipart/form-data',
    },
    {},
    {},
    60000
  );
}
function uploadFiles(data, urlParams) {
  return http.post(
    apis + '/upload' + urlParams,
    data,
    {
      'Content-Type': 'multipart/form-data',
    },
    {},
    {},
    60000
  );
}
function uploadExcel(data, urlParams) {
  return http.post(
    '/fds/organization/import-excel' + urlParams,
    data,
    {
      'Content-Type': 'multipart/form-data',
    },
    {},
    {},
    60000
  );
}
function uploadExcelCustom(data, urlParams) {
  return http.post(
    '/fds/customer/import-excel' + urlParams,
    data,
    {
      'Content-Type': 'multipart/form-data',
    },
    {},
    {},
    60000
  );
}
function uploadExcelAccount(data, urlParams) {
  return http.post(
    '/upms/user/import-excel' + urlParams,
    data,
    {
      'Content-Type': 'multipart/form-data',
    },
    {},
    {},
    60000
  );
}
export {
  uploadImg,
  uploadFile,
  uploadFiles,
  uploadExcel,
  uploadExcelCustom,
  uploadAvatar,
  uploadExcelAccount,
};
