<template>
  <div>
    <k-crud-page>
      <template #condition>
        <div class="d-flex">
          <k-text-input
            v-model="cruddataMX.conditions.customerName"
            class="my-1"
            label="人员名"
          />
          <k-select
            v-model="cruddataMX.conditions.customerType"
            item-text="msg"
            item-value="code"
            :items="dict.CustomerTypeEnum"
            class="my-1"
            label="人员类型"
          />
        </div>

        <k-primary-button
          class="my-1 mr-4"
          text="搜索"
          icon="mdi-magnify"
          @click="crudConditionsMX().resetPage()"
        />
      </template>

      <template #resultlist>
        <k-crud-table
          :single-select="false"
          v-model="selection"
          item-key="uuid"
          :show-select="true"
          :total="cruddataMX.pagination.total"
          :headers="columns"
          :items="cruddataMX.dataList"
          :value="cruddataMX.pagination.current"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title>数据列表</v-toolbar-title>
              <v-spacer></v-spacer>
              <k-primary-button
                v-if="hasPermission(['customer.add'])"
                class="mr-2"
                icon="mdi-account-plus"
                text="新增人员"
                @click="
                  crudActionMX().newItem(crud.default, onOpenEdit(crud.default))
                "
              />
              <k-primary-button
                v-if="hasPermission(['customer.delete'])"
                :disabled="selection.length === 0"
                class="mr-2"
                icon="mdi-delete"
                text="批量删除"
                @click="crudActionMX().deleteItem(selection)"
              />
              <c-upload
                v-if="hasPermission(['customer.import'])"
                :api="uploadExcelCustom"
                btn-type="warning"
                btn-icon="mdi-logout-variant"
                upload-text="导入"
                accept=".xls,.xlsx"
                upload-type="btn"
                class="mr-2"
              ></c-upload>
              <k-secondary-btn
                v-if="hasPermission(['customer.export'])"
                text="导出"
                icon="mdi-login-variant"
                class="mr-2"
                @click="exportsExcel"
              />
              <k-secondary-btn
                v-if="hasPermission(['customer.import'])"
                class="mr-2"
                text="下载模板"
                icon="mdi-login-variant"
                @click="exportsExcelTemp"
              />
            </v-toolbar>
          </template>
          <template #avatarUrl="{ item }">
            <v-img
              v-if="item.avatarUrl"
              class="my-5"
              :lazy-src="commonUrl + item.avatarUrl"
              height="60"
              width="60"
              min-width="60"
              min-height="60"
              :src="commonUrl + item.avatarUrl"
              alt="头像"
              @click.native="showImg(item)"
            >
            </v-img>
            <div v-else>暂无</div>
            <v-overlay
              v-if="item.rowPreview"
              :value="item.rowPreview"
              @click="item.rowPreview = false"
            >
              <v-img
                :lazy-src="commonUrl + item.avatarUrl"
                :src="commonUrl + item.avatarUrl"
              ></v-img>
            </v-overlay>
          </template>
          <template #customerType="{ item }">
            {{ customerType(item.customerType) }}
          </template>
          <template #actions="{ item }">
            <k-table-warning-btn
              v-if="hasPermission(['customer.edit'])"
              text="编辑"
              class="mx-4"
              @click="
                crudActionMX().editItem(item, () => onOpenEdit(item, '01'))
              "
            />
            <k-table-error-btn
              v-if="hasPermission(['customer.delete'])"
              text="删除"
              @click="crudActionMX().deleteItem([item])"
            />
          </template>
        </k-crud-table>
        <k-crud-dialog
          v-model="crud.switch"
          :title="crud.title"
          maxwidth="700px"
          @save="crudActionMX().saveItem()"
          @close="crudActionMX().closeEditingPage(crud)"
        >
          <template #dialogContent>
            <v-form ref="accountModalForm">
              <v-row>
                <v-col :cols="6">
                  <k-text-input
                    v-model="cruddataMX.data.customerNo"
                    label="人员编号"
                    :rules="[globalRules.empty]"
                  />
                  <k-text-input
                    v-model="cruddataMX.data.customerName"
                    label="人员名称"
                    :rules="[globalRules.empty]"
                  />
                  <k-text-input
                    v-model="cruddataMX.data.idNum"
                    label="身份证号"
                    :rules="[globalRules.idCard]"
                  />
                  <k-text-input
                    v-model="cruddataMX.data.telNo"
                    label="手机号码"
                    :rules="cruddataMX.data.telNo ? [globalRules.phone] : []"
                  />
                  <k-select
                    v-model="cruddataMX.data.customerType"
                    :items="dict.CustomerTypeEnum"
                    item-text="msg"
                    item-value="code"
                    label="人员类型"
                    :rules="[globalRules.empty]"
                  />
                  <k-select
                    v-model="cruddataMX.data.level"
                    :items="dict.CustomerLevelEnum"
                    item-text="msg"
                    item-value="code"
                    label="人员等级"
                    :rules="[globalRules.empty]"
                  />
                  <k-text-input
                    v-model="cruddataMX.data.relatedAccount"
                    label="关联账号"
                  />
                  <k-text-input
                    v-model="cruddataMX.data.relatedIdNum"
                    label="关联身份证"
                    :rules="
                      cruddataMX.data.relatedIdNum ? [globalRules.idCard] : []
                    "
                  />
                  <k-text-input
                    v-model="cruddataMX.data.remarks"
                    label="备注"
                  />
                </v-col>
                <v-col :cols="6">
                  <c-upload
                    v-model="cruddataMX.data.avatarUrl"
                    label="图片"
                    :api="uploadImg"
                    upload-type="img"
                  ></c-upload>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </k-crud-dialog>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import { uploadImg } from '@/api/upload';
import httpConfig from '@/config/http.config';
import { easyPoiExcel } from '@/api/exportsExcel';
import { uploadExcelCustom } from '@/api/upload';

export default {
  data() {
    return {
      uploadExcelCustom,
      uploadImg,
      commonUrl: httpConfig.imgUrl,
      selection: [], //表格已选
      rowPreview: false, //预览
      dict: {
        CustomerLevelEnum: [],
        CustomerTypeEnum: [],
      },
      crud: {
        name: '人员',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm',
        },
        default: {
          uuid: null,
          customerNo: '',
          customerName: '',
          idNum: '',
          telNo: '',
          customerType: '',
          level: '',
          relatedAccount: '',
          relatedIdNum: '',
          avatarUrl: '',
          remarks: '',
        },
      },
      columns: [
        { text: '序号', value: 'indexNum', sortable: false },
        { text: '人员名称', value: 'customerName', sortable: false },
        { text: '身份证号', value: 'idNum', sortable: false },
        { text: '联系电话', value: 'telNo', sortable: false },
        { text: '人员类型', value: 'customerType', sortable: false },
        { text: '备注', value: 'remarks', sortable: false },
        { text: '照片', value: 'avatarUrl', sortable: false },
        { text: '更新时间', value: 'updatedTime', sortable: false },
        { text: '操作', value: 'actions', sortable: false },
      ],
    };
  },
  created() {
    this.getDict();
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.customer.customer.page,
        add: this.$api.customer.customer.add,
        edit: this.$api.customer.customer.update,
        delete: (params) => {
          const uuidArr = params.map((item) => item.uuid);
          return this.$api.customer.customer.del(uuidArr);
        },
      },
      {
        customerType: null,
        customerName: null,
      },
      {
        add: [
          'uuid', //uuid必须为空
          'customerNo', //编号 20位 必须
          'customerName', //名称 20位 必须
          'idNum', //身份证 必须
          'telNo', //电话号码
          'customerType', //人员类型 [枚举CustomerTypeEnum] 必须
          'level', //人员等级 [枚举CustomerLevelEnum]
          'relatedAccount', //关联账号
          'relatedIdNum', //关联身份证
          'avatarUrl', //照片
          'remarks', //备注
        ],
        edit: [
          'uuid', //uuid必须为空
          'customerNo', //编号 20位 必须
          'customerName', //名称 20位 必须
          'idNum', //身份证 必须
          'telNo', //电话号码
          'customerType', //人员类型 [枚举CustomerTypeEnum] 必须
          'level', //人员等级 [枚举CustomerLevelEnum]
          'relatedAccount', //关联账号
          'relatedIdNum', //关联身份证
          'avatarUrl', //照片
          'remarks', //备注
        ],
      }
    );
  },
  methods: {
    showImg(item) {
      this.$set(item, 'rowPreview', true);
    },
    exportsExcel() {
      easyPoiExcel(
        '/fds/customer/export-excel',
        '重点人员',
        this.crudConditionsMX().get()
      );
    },
    exportsExcelTemp() {
      easyPoiExcel(
        '/fds/customer/excel-template',
        '重点人员',
        this.crudConditionsMX().get()
      );
    },
    customerType(val) {
      return (
        this.dict.CustomerTypeEnum.filter((item) => item.code === val)[0].msg ||
        '--'
      );
    },
    async onOpenEdit(item, flag) {
      if (flag === '01') {
        await this.$api.customer.customer.info(item).then(({ data, code }) => {
          if (code === 200) {
            this.cruddataMX.data = data[0];
          }
        });
      }
    },
    getDict() {
      this.$api.customer.customer.dict().then(({ code, data }) => {
        if (code === 200) {
          this.dict = data[0];
        }
      });
    },
  },
};
</script>
